import useFade from '../../hooks/useFade';

const FadeMain = ({ data }) => {
  // Background Image
  const fadeLength = data.fade_main.length - 1;
  const { fadeContRef } = useFade();

  return (
    <div ref={fadeContRef} className="fade__cont">
      {
        <img
          src={require(`../../assets/project-img/${data.folder}/${data.fade_main[fadeLength]}`)}
          alt=""
        />
      }
      {data.fade_main.map((item, index) => (
        <img
          className="fade__img"
          style={{ transition: 'opacity 600ms' }}
          key={index}
          src={require(`../../assets/project-img/${data.folder}/${item}`)}
          alt=""
        />
      ))}
    </div>
  );
};
export default FadeMain;
