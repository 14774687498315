import { useState } from 'react';
import useLocalStorage from './useLocalStorage';

const useItemFilter = (data) => {
  // Filter
  const findMatchedItem = (clickedValue) =>
    // type as object key in data.js
    data.filter(({ type }) =>
      type.some((itemType) => itemType.includes(clickedValue))
    );

  const [clickedItem, setClickedItem] = useState();

  const [prevItem, setPrevItem] = useLocalStorage('prevItem');
  const toggleLogic = () => {
    prevItem === 'sameItem'
      ? setPrevItem(clickedItem)
      : prevItem === clickedItem
      ? setPrevItem('sameItem')
      : setPrevItem(clickedItem);
  };

  const matchedItem = findMatchedItem(clickedItem);

  return {
    setClickedItem,
    toggleLogic,
    matchedItem,
    clickedItem,
    prevItem,
  };
};

export default useItemFilter;
