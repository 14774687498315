import { useEffect, useRef, useState } from 'react';

const useSlideToggle = (data) => {
  const [boolean, setBoolean] = useState(false);
  const clickToggle = () => setBoolean(!boolean);
  const sizeRef = useRef();
  const [transProp, setTransProp] = useState({
    overlow: 'hidden',
    height: 0,
    transition: 'height 200ms',
  });
  const [content, setContent] = useState(null);

  useEffect(() => {
    boolean
      ? setContent(data)
      : setTimeout(() => {
          setContent();
        }, 200);
  }, [data, boolean]);
  useEffect(() => {
    if (!boolean) {
      setTransProp({
        overflow: 'hidden',
        height: 0,
        transition: 'height 200ms',
      });
    } else if (content !== null) {
      setTransProp({
        overflow: 'hidden',
        height: `${sizeRef.current.clientHeight}px`,
        transition: 'height 200ms',
      });
    }
  }, [boolean, content]);

  return { clickToggle, sizeRef, transProp, content, setBoolean };
};

export default useSlideToggle;
