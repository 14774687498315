const InfoData = {
  par_1:
    'Klodiana Millona and Yuan Chun Liu\n spatial practitioners working at the intersection of architecture, research and image making. Our work focuses on the politics of inivisibility in space and invisibilized spatial practices in dominant narratives of the built environment through',
  tog_btn:
    ' entangled readings of socio political imaginaries mediated by spatial representation ',
  par_2:
    'Our practice is positioned both within architecture and its refusal. We are based in Rotterdam, but too often follow our roots between Tirana & Taipei.\n Currently, we are engaged in teaching at Royal Academy of Art in The Hague (KABK) and with other independent forms of pedagogy.',
};

export default InfoData;
