import { useEffect, useRef, useState } from 'react';

const useSlide = (prop, imgArray) => {
  const slideRef = useRef();
  const [slideWidth, setWidth] = useState();
  useEffect(() => {
    if (slideRef !== undefined) {
      setWidth(slideRef.current.offsetWidth);
    }
  }, []);

  const imgWidth = slideWidth * prop.width;
  const slideContRef = useRef();
  useEffect(() => {
    if (slideWidth !== undefined) {
      const interval = setInterval(async () => {
        await (slideContRef.current.firstChild.style.width = '0');
        await setTimeout(() => {
          slideContRef.current.append(slideContRef.current.firstChild);
        }, 600);
        slideContRef.current.lastChild.style.width = `${imgWidth}px`;
      }, 2500);
      return () => clearInterval(interval);
    }
  });

  const slideLength = imgArray.length;
  const slideContStyle = {
    width: `${imgWidth * (slideLength + 1)}px`,
    left: `-${imgWidth}px`,
  };

  const imgStyle = {
    width: `${imgWidth}px`,
    transition: 'width 600ms',
  };

  return { slideRef, slideContRef, slideContStyle, imgStyle };
};

export default useSlide;
