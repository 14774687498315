const ImgGal = ({ data }) => {
  const hasValue = (item, prop, value) =>
    item.hasOwnProperty(prop) ? value : null;

  return (
    <>
      {data.img_gal.map((item, index) => (
        <div
          key={index}
          className="proj-flex__two-clm img"
          style={hasValue(item, 'orien', { aspectRatio: '1 / 1' })}>
          <img
            src={require(`../../assets/project-img/${data.folder}/${item.img}`)}
            alt=""
          />
        </div>
      ))}
    </>
  );
};
export default ImgGal;
