const CVData = [
  [
    {
      clm_one: 'Klodiana Millona',
      class: 'name',
      class_one: 'name',
    },
    { clm_one: '1990 | Albania', class: 'bio' },
    {
      clm_one: '2018-2019',
      clm_two: [
        'Sonology One Year Course, Royal Conservatoire The Hague (Koncon), NL',
      ],
      class: 'edu',
    },
    {
      clm_one: '2015-2017',
      clm_two: [
        'Interior Architecture (MA), Royal Academy of Art, Den Haag, NL',
      ],
      class: 'edu',
    },
    {
      clm_one: '2008-2013',
      clm_two: ['Architecture (BSc), Epoka University, Tirana, AL'],
      class: 'exc',
    },
    { clm_one: 'Yuan Chun Liu', class: 'name', class_one: 'name' },
    { clm_one: '1987 | Taiwan', class: 'bio' },
    {
      clm_one: '2015-2018',
      clm_two: [
        'Interior Architecture (MA), Royal Academy of Art, Den Haag, NL',
      ],
      class: 'edu',
    },
    {
      clm_one: '2006-2011',
      clm_two: [
        'Interior and Landscape Design (BSc), Feng Chia University, Taichung, TW',
      ],
      class: 'edu',
    },
  ],
  //   EXHIBITION
  [
    { clm_one: 'Exhibitions', class: 'title' },
    {
      clm_one: '2022',
      clm_two: [
        'Politics of Space, Culture Complex Sodas 2123, Vilnius, LT',
        'Manifesta 14 Prishtina, XK',
        `GPC STREAMS (#1) "The Beginning Is The End Is The Beginning", The Golden Pixel Cooperative, Vienna, AT`,
        'Open Studio Arcus Residency Project Japan, Ibaraki, JP',
        `Digital Launch of 'Wave Function Collapse - In Defence of the Ear', Radio Alhara, Betlehem, PS`,
      ],
    },
    {
      clm_one: '2021',
      clm_two: [
        `Kyiv Biennial 2021, "Allied", curated by East Europe Biennial Alliance, The House of Cinema, Kyiv, UA`,
        `"Who Builds The City", C-LAB (Contemporary Culture Lab), Taipei, TW`,
        'Wild Out Video Festival, Taipei, TW',
        'Video Art and Experimental Film Event, SEA Foundation, Tilburg, NL',
        '17th Athens Digital Arts Festival, Athens, GR',
        '12th Edition Balkans Beyond Borders, Kino Armata, Prishtina, XK',
      ],
    },
    {
      clm_one: '2020',
      clm_two: [
        'Tbilisi Architecture Biennial 2020, Tbilisi, GE',
        `Group Exhibition "What Do We Have in Common" Moving Pavilion, Kyiv, Dnipro, Ivano Frankivsk, UA`,
        'Dutch Design Week, Platform Talent, Mu Hybrid Art House, Eindhoven, NL',
      ],
    },
    {
      clm_one: '2019',
      clm_two: [
        `"Awkward Introductions Radio", Showroom MAMA, Rotterdam, NL`,
        'A Festival for Creative Urban Living, Milton Keynes, UK',
        `"Taking The Kitchen's Side", Lisbon Architecture Triennale 2019, PT`,
        `"No Money, No Cry", Oslo Architecture Triennale 2019 NO`,
        `City Scanning Festival "Manufuturing", Ivano Frankivsk UA`,
      ],
    },
    {
      clm_one: '2018',
      clm_two: [
        `"My Practice, My Politics", Stroom, Den Haag, NL`,
        `"Salone Del Mobile", Milano, IT`,
        `"Circling the Square", Royal Academy of Art, Den Haag NL`,
        `Group Performance "Woodstone Kugelblitz: Druk in Varia", Varia, Rotterdam, NL`,
      ],
    },
    {
      clm_one: '2017',
      clm_two: [
        `"A State of Design" festival, Berlin, DE`,
        `"Decision Making Laboratory", Goethe Institute, Rotterdam, NL`,
        `"We Prefer Not To", Royal Academy of Art, Den Haag, NL`,
      ],
    },
    {
      clm_one: '2016',
      clm_two: [`"Embracing Braambergen", Braambergen, Almere, NL`],
    },
  ],
  // RESIDENCY
  [
    { clm_one: 'Residency', class: 'title' },
    {
      clm_one: '2022-23',
      clm_two: [
        `A Natural Oasis?, BJCEM - Association Biennale des Jeunes Créateurs de l'Europe et de la Méditerranée, IT`,
      ],
    },
    { clm_one: '2020-21', clm_two: ['ARCUS Artist Residency, Ibaraki, JP'] },
    {
      clm_one: '2019',
      clm_two: [
        'Creation of Education Activities, Lisbon Architecture Triennale 2019, PT',
        `Artist Residency "We Are Never Alone", Ivano Frankivsk, UA`,
      ],
    },
  ],
  // CURATING
  [
    { clm_one: 'Curating', class: 'title' },
    {
      clm_one: '2020',
      clm_two: [`"Other Ways of Watching Together", ShowRoom MAMA, Rotterdam`],
    },
    {
      clm_one: '2018-19',
      clm_two: [
        `"Situated Knowledge" Lecture Series, Royal Academy of Art, Den Haag, NL`,
      ],
    },
    {
      clm_one: '2018',
      clm_two: [`"Nights of Noise", Neverland Cinema, Rotterdam, NL`],
    },
    {
      clm_one: '2017',
      clm_two: [`"Fertilizing Impotent Gardens", DCR building, Den Haag, NL`],
    },
  ],
  // WORKSHOP
  [
    { clm_one: 'Teaching / Workshops', class: 'title' },
    {
      clm_one: '2022',
      clm_two: [
        'You May Dream Workshop, part of Politics of Space, Culture Complex Sodas 2123, Vilnius, LT',
        'Mentor in the Summer School (Working on) Common Ground, Manifesta 14, Prishtina, XK',
        'Mentoring at Experiments Platform, an Initiative of Architektūros Fondas, Vilnius, LT',
      ],
    },
    {
      clm_one: '2019 - 2022',
      clm_two: [
        'Urban Studio at the Master of Interior Architecture, Royal Academy of Art, Den Haag, NL',
      ],
    },
    {
      clm_one: '2021',
      clm_two: [
        `Workshop "Cartographies of Multiple Entanglements" part of "Future Ecologies", British Council & LUX, AL`,
        `Workshop "Inside-Out" with Jack Bardwell, Royal Academy of Art, Den Haag, NL`,
      ],
    },
    {
      clm_one: '2020',
      clm_two: [
        'Workshop with Master of Industrial Design, Royal Academy of Art, Den Haag, NL',
      ],
    },
    {
      clm_one: '2017-18',
      clm_two: [
        'Teaching Assistant at the Master of Interior Architecture, Royal Academy of Art, Den Haag, NL',
      ],
    },
    {
      clm_one: '2017',
      clm_two: [
        `"How to design a workshop" at the Master of Interior Architecture, Royal Academy of Art, Den Haag, NL`,
        `Workshop "Gaming the City" with master students of Interior Architecture, Royal Academy of Art, Den Haag, NL`,
        `Workshop "The Great Failure", Royal Academy of Art, Den Haag, NL`,
      ],
    },
    {
      clm_one: '2016',
      clm_two: [
        `Workshop "Nomad, Yes Mad", Royal Academy of Art, Den Haag, NL`,
      ],
    },
  ],
  // LECTURE
  [
    { clm_one: 'Talks / Lectures', class: 'title' },
    {
      clm_one: '2022',
      clm_two: [
        `Sticky Entanglements, "Uncivilized Paradigm" part of DEconstruct and REbuild Metochi Study Centre, GR`,
        'Exhausted Images: Politics of Sound, Cultural Center ATA, Kamez, AL',
      ],
    },
    {
      clm_one: '2021',
      clm_two: [
        `Artist Talk "Sticky Entanglements", ARCUS Project, Ibaraki, JP`,
      ],
    },
    {
      clm_one: '2020',
      clm_two: [
        `Live Presentation "Earthmovers: Surpassing the Blade",Tbilisi, GE`,
        'Live Presentation at ARCUS, Ibaraki, JP',
        'Artist Talk at Royal Academy of Art, Den Haag, NL',
      ],
    },
    {
      clm_one: '2019',
      clm_two: [
        'Artist Talk at the City Scanning Festival, Ivano Frankivsk, UA',
        'Artist Talk during Publishing Acts III, Rijeka, HR',
        `Performative Screening "The Parable of Long Spoons",Neverland Cinema, Rotterdam, NL`,
        'Invited Speaker at Creative Exchange 2019, MAO Museum, Ljubljana, SI',
      ],
    },
    {
      clm_one: '2018',
      clm_two: [
        `Invited Speaker Dutch Design Week : Group Class: "The Power of collectives", Eindhoven, NL`,
        `Artist talk "It's not the fault of the House", Fuori Salone, Milano, IT`,
      ],
    },
    {
      clm_one: '2017',
      clm_two: [
        `Artist talk "Open Source Government", W139, Amsterdam, NL`,
        `Artist talk "Divanya", Showroom MAMA, Rotterdam, NL`,
      ],
    },
  ],
  // PUBLICATION
  [
    { clm_one: 'Publications', class: 'title' },
    {
      clm_one: '2022',
      clm_two: [
        `"Wave Function Collapse- In Defence of the Ear", Rotterdam, NL`,
      ],
    },
    {
      clm_one: '2021',
      clm_two: [
        '(Upcoming) Architecture of Citizenship, Rotterdam, NL',
        `Contributor for "What do we have in common", Tbilisi Architecture Biennial, Tbilisi, GE`,
      ],
    },
    {
      clm_one: '2020',
      clm_two: [
        `Invited Contributor "Publishing Act III : Fiume Novare", published by DAI-SAI & dpr-barcelona, Rijeka, HR`,
        'Invited Contributor for the 11th issue of Inside Magazine, Den Haag, NL',
        `Publication "What Do We Talk About When We Talk About Commons", Rotterdam, NL`,
      ],
    },
    {
      clm_one: '2019',
      clm_two: [
        'Invited Contributor for the opening texts of the 10th issue of Inside Magazine, Den Haag, NL',
        'Guest Editor for the digital platform Architectuul, Berlin, DE',
        `Contributor to the Issue "On Struggles" of KAJET Journal, RO`,
      ],
    },
  ],
  // PRIZE
  [
    { clm_one: 'Grants / Prizes', class: 'title' },
    { clm_one: '(millonaliu)', class: 'r-name-top' },
    {
      clm_one: '2022',
      clm_two: [
        'Stimulering Fund Creative Industry, Internationalization Grant',
      ],
    },
    {
      clm_one: '2021',
      clm_two: [
        'Stimulering Fund Creative Industry, Experiment Grant',
        'Stimulering Fund Creative Industry, Architecture Grant',
        'Stimulering Fund Creative Industry, Professionalization Design Practice',
        'Shortlisted for Young Innovators 2021, College van Rijksadviseurs, CRa/Yi',
        'Official Selection for the Competition Program, 12th Balkans Beyond Borders Short Film Festival',
        `The Most Represented Video of this year and Official Finalist for "Wild Out Video Festival", Taipei, TW`,
      ],
    },
    { clm_one: '2020', clm_two: ['Amarte Fonds'] },
    {
      clm_one: '2019-20',
      clm_two: ['Stimulering Fund Creative Industry, Talent Development'],
    },
    { clm_one: '(Yuan Chun Liu)', class: 'r-name' },
    {
      clm_one: '2021',
      clm_two: ['Mondrian Fund Artist Project Grant', 'Makersloket Grant'],
    },
    {
      clm_one: '2019',
      clm_two: ['Selected Creative 2019 of the Future Architecture Platform'],
    },
    {
      clm_one: '2018',
      clm_two: ['Stimulering Fund Creative Industry for Architecture'],
    },
    { clm_one: '(Klodiana Millona)', class: 'r-name' },
    { clm_one: '2022', clm_two: ['Ministry of Culture of Albania'] },
    { clm_one: '2021', clm_two: ['CBK', 'Makersloket Grant'] },
    {
      clm_one: '2019',
      clm_two: [
        'Selected Creative 2019 of the Future Architecture Platform',
        'Shortlisted Candidate for the Digital Research Fellowship lunched by Architectuul and Future Architecture Platform',
        'Nominated for the International Archiprix 2019, CL',
      ],
    },
    {
      clm_one: '2018',
      clm_two: [
        'Shortlisted for the Young Talent Architecture Award 2018 (YTAA)',
        `Nominated at Salone Del Mobile for the Future Dome Award 2018' Category : "Stabilizing Communities", Milano, IT`,
      ],
    },
    {
      clm_one: '2017',
      clm_two: [
        'Winner of the Best Department Graduation from Royal Academy of Art',
        'Nominated for Best Written Thesis from Royal Academy of Art',
      ],
    },
  ],
];

export default CVData;
