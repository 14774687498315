import { useEffect, useRef, useState } from 'react';

const useTravers = (size) => {
  const [trvsBoolean, setTrvsBoolean] = useState(false);
  const clickToggle = () => setTrvsBoolean(!trvsBoolean);
  const query = window.matchMedia(
    size === 'sm'
      ? '(min-width: 576px)'
      : size === 'md'
      ? '(min-width: 768px)'
      : size === 'lg'
      ? '(min-width: 992px)'
      : '(min-width: 1200px)'
  );
  const sizeRef = useRef();
  const [leftComp, setLeftComp] = useState();
  const [rightComp, setRightComp] = useState();

  useEffect(() => {
    !trvsBoolean
      ? (() => {
          setLeftComp({
            left: 0,
            transition: 'left 200ms',
          });
          setRightComp({
            right: `-${sizeRef.current.clientWidth}px`,
            transition: 'right 200ms',
          });
        })()
      : (() => {
          setLeftComp({
            left: `-${sizeRef.current.clientWidth}px`,
            transition: 'left 200ms',
          });
          setRightComp({
            right: 0,
            transition: 'right 200ms',
          });
        })();
  }, [trvsBoolean]);

  useEffect(() => {
    const rePosition = () => {
      query.matches
        ? (() => {
            setLeftComp({ left: 'auto' });
            setRightComp({ right: 'auto' });
          })()
        : (() => {
            setLeftComp({
              left: 0,
              transition: 'left 200ms',
            });
            setRightComp({
              right: `-${sizeRef.current.clientWidth}px`,
              transition: 'right 200ms',
            });
          })();
    };
    rePosition();

    window.addEventListener('resize', rePosition);
    return () => {
      window.addEventListener('resize', rePosition);
    };
  }, [query.matches]);

  return {
    clickToggle,
    setTrvsBoolean,
    sizeRef,
    leftComp,
    rightComp,
  };
};
export default useTravers;
