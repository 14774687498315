import { Link } from 'react-router-dom';

const ProjItem = ({ item, setTrvsBoolean }) => {
  return (
    <li>
      <Link
        to={item.title
          .toLowerCase()
          .replaceAll(' ', '_')
          .replaceAll(/'/g, '')
          .replaceAll('?', '')}
        onClick={() => {
          if (!window.matchMedia('(min-width: 992px)').matches) {
            setTrvsBoolean(false);
          }
        }}>
        {item.title.replaceAll('Publication', '')}
      </Link>
    </li>
  );
};
export default ProjItem;
