import { useRef, useEffect, useState } from 'react';
import ProjItem from './ProjItem';

const ProjList = ({
  setTrvsBoolean,
  clickedItem,
  prevItem,
  matchedItem,
  item,
}) => {
  const [content, setContent] = useState();
  const [transProp, setTransProp] = useState({
    overflow: 'hidden',
    height: 0,
    margin: '0 0 0 15px',
    transition: 'height 200ms, margin 100ms',
  });
  const contRef = useRef();
  const heightRef = useRef();

  useEffect(() => {
    if (
      content !== undefined &&
      heightRef.current !== null &&
      contRef.current.clientHeight === 0
    ) {
      setTransProp({
        overflow: 'hidden',
        height: `${heightRef.current.clientHeight}px`,
        margin: '5px 0 20px 15px',
        transition: 'height 200ms, margin 100ms',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const outPut = (
    <div ref={contRef} style={transProp}>
      <div ref={heightRef}>
        {matchedItem.map((item, index) => (
          <ProjItem key={index} item={item} setTrvsBoolean={setTrvsBoolean} />
        ))}
      </div>
    </div>
  );

  const fold = async () => {
    await setContent(outPut);
    await setTransProp({
      overflow: 'hidden',
      height: 0,
      margin: '0 0 0 15px',
      transition: 'height 200ms, margin 100ms',
    });
    setTimeout(() => {
      setContent();
    }, 200);
  };

  useEffect(() => {
    if (content !== undefined && item !== clickedItem) {
      fold();
    } else if (content !== undefined && clickedItem === prevItem) {
      fold();
    } else if (clickedItem === item) {
      setContent(outPut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevItem, transProp.height]);

  return <ul>{content}</ul>;
};
export default ProjList;
