const ExtLink = ({ link }) => {
  return (
    <>
      {link.map((item, index) => (
        <>
          {item.text}
          <a key={index} href={item.href} target="blank">
            <u>{item.hyperlink}</u>
          </a>
        </>
      ))}
    </>
  );
};
export default ExtLink;
