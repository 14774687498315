import { useContext, useState } from 'react';
import InfoData from '../../data/InfoData';
import Info from './Info';
import ProjTypeList from './ProjTypeList';
import useSlideToggle from '../../hooks/useSlideToggle';
import HomeBtn from '../ui/HomeBtn';
import useMediaquery from '../../hooks/useMediaquery';
import useItemFilter from '../../hooks/useItemFilter';
import Context from '../../context/Context';

const Nav = ({ setTrvsBoolean }) => {
  const [homeBtn] = useMediaquery('lg', 'bgr', <HomeBtn />);

  const [info] = useState(InfoData);
  const { setBoolean, clickToggle, sizeRef, transProp, content } =
    useSlideToggle(info.par_2);

  const { typeList, project } = useContext(Context);
  const { toggleLogic, setClickedItem, clickedItem, matchedItem, prevItem } =
    useItemFilter(project);

  return (
    <>
      {homeBtn}
      <Info
        info={info}
        clickToggle={clickToggle}
        sizeRef={sizeRef}
        transProp={transProp}
        context={content}
        setTrvsBoolean={setTrvsBoolean}
        toggleLogic={toggleLogic}
        setClickedItem={setClickedItem}
      />
      <ProjTypeList
        setBoolean={setBoolean}
        setTrvsBoolean={setTrvsBoolean}
        typeList={typeList}
        toggleLogic={toggleLogic}
        setClickedItem={setClickedItem}
        clickedItem={clickedItem}
        matchedItem={matchedItem}
        prevItem={prevItem}
      />
    </>
  );
};
export default Nav;
