import { createContext, useState } from 'react';
import ProjData from '../data/ProjData';

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [project] = useState(ProjData);

  const typeList = [
    'Installations / Interventions',
    'Critical Cartography',
    'Film',
    'Publication',
    'Pedagogy',
  ];

  return (
    <Context.Provider
      value={{
        project,
        typeList,
      }}>
      {children}
    </Context.Provider>
  );
};

export default Context;
