import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from '../context/Context';

const IndexPage = ({ setTrvsBoolean }) => {
  const { project } = useContext(Context);
  return (
    <div className="index__page">
      {project.map((item, index) => (
        <Link
          className="index__link"
          key={index}
          to={item.title
            .toLowerCase()
            .replaceAll(' ', '_')
            .replaceAll(/'/g, '')
            .replaceAll('?', '')}
          onClick={() => {
            setTrvsBoolean(false);
          }}
          state={item}
        >
          <img
            className="index__img"
            src={require(`../assets/project-img/${item.folder}/${item.img_index}`)}
            alt=""
          />
        </Link>
      ))}
    </div>
  );
};
export default IndexPage;
