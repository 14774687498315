import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Context from '../context/Context';
import Title from '../components/proj/Title';
import Descr from '../components/proj/Descr';
import ImgMain from '../components/proj/ImgMain';
import ImgSub from '../components/proj/ImgSub';
import ImgGal from '../components/proj/ImgGal';
import Slide from '../components/proj/Slide';
import FadeMain from '../components/proj/FadeMain';
import FadeWrap from '../components/proj/FadeWrap';
import Vimeo from '../components/proj/Vimeo';
import VimeoScript from '../components/proj/VimeoScript';
import Credit from '../components/proj/Credit';

const ProjectPage = () => {
  // target matched project
  const { project } = useContext(Context);
  let { proj } = useParams();
  const data = project.find(
    ({ title }) =>
      title
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll(/'/g, '')
        .replaceAll('?', '') === proj
  );

  // check if has value
  const hasValue = (item, component) =>
    !data.hasOwnProperty(item) ? null : component;

  return (
    <div className="proj-flex">
      {hasValue('fade_main', <FadeMain data={data} />)}
      {hasValue('img_main', <ImgMain data={data} />)}
      {hasValue('title', <Title data={data} />)}
      {hasValue('descr', <Descr data={data} />)}
      {hasValue('img_sub', <ImgSub data={data} />)}
      {hasValue('vimeo', <Vimeo data={data.vimeo} />)}
      {hasValue('img_gal', <ImgGal data={data} />)}
      {hasValue('fade', <FadeWrap data={data} />)}
      {hasValue('slide', <Slide data={data} />)}
      {hasValue('credit', <Credit data={data} />)}
      {hasValue('vimeo', <VimeoScript />)}
    </div>
  );
};
export default ProjectPage;
