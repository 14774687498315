import { useState } from 'react';
import CVData from '../../data/CVData';
import CVList from './CVList';

const CV = () => {
  const [cv] = useState(CVData);
  return (
    <div className="cv">
      {cv.map((item, index) => (
        <CVList key={index} cat={item} />
      ))}
    </div>
  );
};
export default CV;
