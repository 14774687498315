import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ target }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector(target).scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return null;
};
export default ScrollToTop;
