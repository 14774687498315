const CVItem = ({ item }) => {
  return (
    <div
      className={`cv__item${
        !item.hasOwnProperty('class') ? '' : `--${item.class}`
      }`}>
      <div
        className={`cv__clm-one${
          !item.hasOwnProperty('class_one') ? '' : `--${item.class}`
        }`}>
        {item.clm_one}
      </div>
      <div className="cv__clm-two">
        {!item.hasOwnProperty('clm_two')
          ? null
          : item.clm_two.map((item, index) => (
              <div className="cv__clm-two__item" key={index}>
                {item}
              </div>
            ))}
      </div>
    </div>
  );
};
export default CVItem;
