const ImgSub = ({ data }) => {
  return (
    <img
      className="proj-flex__two-clm img-top"
      src={require(`../../assets/project-img/${data.folder}/${data.img_sub}`)}
      alt=""
    />
  );
};
export default ImgSub;
