import ExtLink from './ExtLink';

const Descr = ({ data }) => {
  const hasValue = (item, component) =>
    !data.hasOwnProperty(item) ? null : component;
  return (
    <div
      className={`${
        data.descr.clm === 'two' ? 'proj-flex__two-clm' : 'proj-flex__two-third'
      } push-btm`}>
      {data.descr.text}
      {hasValue('link', <ExtLink link={data.link} />)}
      {data.descr_note}
    </div>
  );
};
export default Descr;
