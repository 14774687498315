const Title = ({ data }) => {
  return (
    <div className="proj-flex__one-clm">
      <div className="title__cont">
        <span className="title">
          {data.title.replaceAll('Publication', '')}
        </span>
        <span className="title">{data.title_second}</span>
        <span className="title jp">{data.title_jp}</span>
        <div>{data.title_sub}</div>
      </div>
      <div>{data.info}</div>
    </div>
  );
};
export default Title;
