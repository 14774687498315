import { Link } from 'react-router-dom';

const Info = ({
  info,
  clickToggle,
  sizeRef,
  transProp,
  context,
  setTrvsBoolean,
  toggleLogic,
  setClickedItem,
}) => {
  return (
    <div className="nav__info">
      <div>
        {info.par_1}
        <span
          className="nav__info__tog-btn"
          onClick={() => {
            clickToggle();
            toggleLogic();
            setClickedItem('info');
          }}>
          {info.tog_btn}
        </span>
        <div style={transProp}>
          <div ref={sizeRef}>{context}</div>
        </div>
      </div>
      {'\n'}
      <Link
        to="/cv"
        onClick={() => {
          if (!window.matchMedia('(min-width: 992px)').matches) {
            setTrvsBoolean(false);
          }
        }}>
        CV
      </Link>
      <span>&nbsp;&nbsp;.&nbsp;&nbsp;</span>
      <a
        rel="noreferrer"
        href="https://www.instagram.com/millonaliu/"
        target="_blank">
        Instagram
      </a>
      <span>&nbsp;&nbsp;.&nbsp;&nbsp;</span>
      <a href="mailto:millonaliu@gmail.com">Mail</a>
    </div>
  );
};
export default Info;
