import { useEffect, useState } from 'react';

const useMediaquery = (size, targeting, insert) => {
  const [output, setOutput] = useState();

  useEffect(() => {
    const query = window.matchMedia(
      size === 'sm'
        ? '(min-width: 576px)'
        : size === 'md'
        ? '(min-width: 768px)'
        : size === 'lg'
        ? '(min-width: 992px)'
        : '(min-width: 1200px)'
    );
    const queryInsert = () => {
      targeting === 'smr'
        ? query.matches
          ? setOutput()
          : setOutput(insert)
        : query.matches
        ? setOutput(insert)
        : setOutput();
    };
    queryInsert();
    window.addEventListener('resize', queryInsert);
    return () => {
      window.addEventListener('resize', queryInsert);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [output];
};

export default useMediaquery;
