import { useEffect, useRef, useState } from 'react';

const useFade = () => {
  const fadeContRef = useRef();
  const [fileWidth, setFileWidtg] = useState();
  const [fileHeight, setFileHeight] = useState();
  const [prntWidth, setPrntWidth] = useState();
  const update = () => {
    setPrntWidth(fadeContRef.current.parentElement.clientWidth);
  };
  const scale = prntWidth / fileWidth;
  const contWidth = fileWidth * scale;
  const contHeight = fileHeight * scale;

  // Container Sizing
  useEffect(() => {
    fadeContRef.current.firstChild.onload = () => {
      setFileWidtg(fadeContRef.current.firstChild.naturalWidth);
      setFileHeight(fadeContRef.current.firstChild.naturalHeight);
      setPrntWidth(fadeContRef.current.parentElement.clientWidth);
      window.addEventListener('resize', update);
    };
    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);

  // Loop
  useEffect(() => {
    if (fadeContRef.current.lastChild !== undefined || null) {
      const interval = setInterval(async () => {
        await (fadeContRef.current.lastChild.style.opacity = 0);
        await setTimeout(() => {
          fadeContRef.current.prepend(fadeContRef.current.lastChild);
        }, 600);
        fadeContRef.current.firstChild.style.opacity = 1;
      }, 2500);

      return () => clearInterval(interval);
    }
  }, []);

  return { fadeContRef, contWidth, contHeight };
};

export default useFade;
