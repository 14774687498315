const Credit = ({ data }) => {
  return (
    <>
      {data.credit.map((item, index) => (
        <div
          key={index}
          className={
            item.clm === 'one' ? 'proj-flex__two-third' : 'proj-flex__two-clm'
          }>
          {item.data.map((item, index) => (
            <div
              key={index}
              style={{
                fontWeight: `${item.font}`,
                marginBottom: `${item.gap}px`,
              }}>
              {item.item}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
export default Credit;
