import HomeBtn from '../ui/HomeBtn';

const Header = ({ clickToggle, setTrvsBoolean }) => {
  return (
    <div className="header">
      <HomeBtn setTrvsBoolean={setTrvsBoolean} />
      <div
        className="header__nav-btn"
        onClick={() => {
          clickToggle();
        }}>
        info
      </div>
    </div>
  );
};

export default Header;
