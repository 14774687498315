import useFade from '../../hooks/useFade';

const Fade = ({ item, folder }) => {
  const { fadeContRef, contWidth, contHeight } = useFade();

  return (
    <div
      ref={fadeContRef}
      className="fade__cont"
      style={
        contHeight !== undefined || null
          ? { height: `${contHeight}px`, width: `${contWidth}px` }
          : null
      }>
      {item.map((item, index) => (
        <img
          className="fade__img"
          style={{ transition: 'opacity 600ms' }}
          key={index}
          src={require(`../../assets/project-img/${folder}/${item}`)}
          alt=""
        />
      ))}
    </div>
  );
};
export default Fade;
