const Vimeo = ({ data }) => {
  return (
    <div className={`proj-flex__${data.size}`} style={{ padding: '0 5.61%' }}>
      <div
        style={{
          padding: '112.59% 0 0 0',
          position: 'relative',
        }}>
        <iframe
          title="vimeo"
          src={`${data.src}`}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          frameBorder="0"
          allow={`${data.allow}`}></iframe>
      </div>
    </div>
  );
};
export default Vimeo;
