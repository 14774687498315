const ProjData = [
  // The Parable of The Long Spoons
  {
    title: 'The Parable of The Long Spoons',
    type: ['Pedagogy'],
    info: '2019, participatory performance, 30:00\nNeverland Cinema, Rotterdam, NL',
    descr: {
      clm: 'one',
      text: 'Kitchen politics and practices of caring through de-commodifying food ecologies.',
    },
    credit: [
      {
        clm: 'one',
        data: [
          { item: '*in collaboration with no purposecollective' },
          { item: 'pictures by Pichaya Puapoomcharoen' },
        ],
      },
    ],
    folder: 'Long_Spoon',
    img_index: 'LS_01.jpg',
    img_main: 'LS_02.jpg',
    img_gal: [{ img: 'LS_01.jpg' }, { img: 'LS_03.jpg' }],
  },

  // Sticky Entanglements
  {
    title: 'Sticky Entanglements',
    title_jp: ' / ねばねばした絡み合い',
    type: ['Critical Cartography', 'Film'],
    info: '2022, two-channel video, 11:25\nARCUS Project, Ibaraki, JP',
    descr: {
      clm: 'two',
      text: 'Looking at crops and soil as living archives, we have been exploring the critical materiality and hidden narratives of cross breeding rice; addressing landscape formation and the cultivation and commercialisation of genetically modified crops as a technology of control. Focusing on entangled readings of Hōrai rice, ― the Japanese Empire rice engineering project in colonial Taiwan (1895-1945)― our research attempts to visibilize a counter cartography within the engineered body of the seed where varying layers of power, knowledge, science and ecologies are ingrained within.\n\n',
    },
    link: [
      {
        hyperlink: 'more on the open studio',
        href: 'https://www.arcusprojectopenstudios.com/artist/millonaliu/',
      },
    ],
    descr_note: '\n\n*pictures by Hajime Kato',
    folder: 'Sticky_Entanglements',
    img_index: 'SE_01.gif',
    img_main: 'SE_02.jpg',
    img_sub: 'SE_03.jpg',
    fade: {
      clm: 'one',
      ch1: [
        'SE_09_L.jpg',
        'SE_08_L.jpg',
        'SE_07_L.jpg',
        'SE_06_L.jpg',
        'SE_05_L.jpg',
        'SE_04_L.jpg',
      ],
      ch2: [
        'SE_09_R.jpg',
        'SE_08_R.jpg',
        'SE_07_R.jpg',
        'SE_06_R.jpg',
        'SE_05_R.jpg',
        'SE_04_R.jpg',
      ],
    },
    credit: [
      {
        clm: 'one',
        data: [
          { item: 'Research, Production and Editing', font: 500 },
          { item: 'Klodiana Millona & Yuan Chun Liu', gap: 10 },
          { item: 'Sound Composition', font: '500' },
          { item: 'Giulia Francavilla (Rae)' },
          {
            item: "excerpts of 'Allied' live album (released on January 2021 by Ovaal) mixed with other materials from archive",
            gap: 10,
          },
          { item: 'The Pestle of Sun Moon Lake', font: '500' },
          {
            item: `from 'Music of Taiwan' recording published 1929 / 1943 reprint`,
          },
          { item: 'publisher Nissho Industry Co.,Ltd' },
          { item: 'National Museum of Taiwan History', gap: 10 },
          { item: 'Voice', font: '500' },
          { item: 'Klodiana Millona', gap: 10 },
          { item: 'CG', font: '500' },
          { item: 'Yuan Chun Liu', gap: 10 },
          { item: 'Japanese Translation and Adaptation', font: '500' },
          { item: 'Kanoko Tamura (Art Translators Collective)' },
          { item: 'Yuki Harukawa (Art Translators Collective)' },
        ],
      },
    ],
  },

  // Carbon Copy
  {
    title: 'Carbon Copy',
    title_jp: ' / 同質化',
    type: ['Installations / Interventions'],
    info: `2021, performance / interactive installation, mixed media, 07:00:00\n"Who builds the City", Taiwan Contemporary Culture Lab, Taipei, TW`,
    descr: {
      clm: 'two',
      text: `On interconnectedness and sameness within our domestics. The installation is a replica of our own home working desk in Rotterdam, cloned in Taipei as part of the exhibition 'Who Builds the City' at Taiwan Contemporary Cultural Lab curated by Yu-Chin Ku, I-Chieh Liu and Huai-Chih Liang\n\n Tracing the origins and networks of circulation of these objects that exist simultaneously in the same arrangement in distanced places the work tries to address the pressure of global markets and corporations under which cities are becoming more homogenous, local cultures being assimilated and resources exploited.`,
    },
    credit: [{ clm: 'one', data: [{ item: '*pictures by James Teng' }] }],
    vimeo: {
      clm: 'two-clm',
      src: 'https://player.vimeo.com/video/535900170?h=6f014bfcfe&autoplay=1&loop=1&title=0&byline=0&portrait=0',
      allow: 'autoplay',
    },
    folder: 'Carbon_Copy',
    img_index: 'CC_01.jpg',
    img_main: 'CC_01.jpg',
    img_gal: [{ img: 'CC_02.jpg' }, { img: 'CC_07.jpg' }],
  },

  // Real State
  {
    title: 'Real State',
    type: ['Installations / Interventions'],
    info: `2022, print, acrylic, led\n"Politics of Space", Atletika Gallery, Cultural Centre SODAS 2123, Vilnius, LT`,
    descr: {
      clm: 'two-third',
      text: 'A real estate window display proposing (an)other view on the real state of things outside the commodifying gaze of the housing market.\n\nThe work revisits the disputed informal expansions in Taipei, Taiwan, visibilizing other forms of living beyond property regime and that are in the first place a result of ownership hegemony',
    },
    link: [
      {
        hyperlink: `\n\nread more here`,
        href: 'https://www.atletikaprojects.lt/en/2022-09-29-10-16-paroda-simpoziume-erdves-politika/',
      },
    ],
    folder: 'Real_State',
    img_index: 'RS_01.jpg',
    img_main: 'RS_02.jpg',
    img_gal: [{ img: 'RS_03.jpg' }, { img: 'RS_04.jpg' }],
    credit: [
      {
        clm: 'one',
        data: [
          { item: '*curated by Gabrielė Černiavskaja' },
          { item: '*pictures by Vitalis Vitaleus' },
        ],
      },
    ],
  },

  // The Factory Was Never Closed
  {
    title: 'The Factory Was Never Closed',
    type: ['Critical Cartography', 'Pedagogy'],
    info: '2022, intervention, mural, bricks, clay,found objects, 800 x 250 cm\nManifesta 14, Prishtina, XK',
    descr: {
      clm: 'two-third',
      text: 'A polyphonic cartography that takes the site of the former Brick Factory in Prishtina as a living archive tracing its multiple contested histories of both human and non humans.\n\nIn collaboration with Veronika Zaripova, the former workers of Tulltorja factory and the participants of the workshop.\nPart of the intervention [Working on] Common Ground by ',
    },
    link: [
      {
        hyperlink: 'raumlaborberlin\n',
        href: 'https://raumlabor.net',
      },
      { hyperlink: 'manifesta 14\n\n', href: 'https://manifesta14.org' },
    ],
    descr_note: 'pictures by Atdhe Mulla,  Giulia Ficarazzo, Jan Liesegang',
    folder: 'The_Factory_Was_Never_Closed',
    img_index: 'FWNC_01.jpg',
    fade_main: [
      'FWNC_09.jpg',
      'FWNC_08.jpg',
      'FWNC_07.jpg',
      'FWNC_06.jpg',
      'FWNC_05.jpg',
      'FWNC_04.jpg',
      'FWNC_03.jpg',
      'FWNC_02.jpg',
    ],
  },

  // Aural Ecologies
  {
    title: 'Wave Function Collapse',
    title_sub: 'In Defence of the Ear',
    type: ['Critical Cartography', 'Publication'],
    info: '2022, aural publication, field recording, 01:04:05',
    descr: {
      clm: 'two',
      text: `A collaborative project that started during a lockdown relatively synchronized around the world, including our own locations; Kamza- Tirana- Rotterdam- Belgrade- Berlin -L'Aquila. Each work focuses on what by looking has been collapsed into a state that disables a present possibility, what is and remains unseen but which is entangled with what is visible and which stands inaudible.\n\nThe outcome consists of sound works coming together in a dedicated digital space, a cassette tape and publication that are put together in dialogue and offer an expanded reading of the project(s).\n\n`,
    },
    link: [
      {
        text: 'more at ',
        hyperlink: 'wavefunctioncollapse.net',
        href: 'https://wavefunctioncollapse.net',
      },
    ],
    descr_note:
      '\n\nWith Diana Malaj w/ ATA, Giulia Francavilla, Tijana Cvetkovic w/Vahida Ramujkić (minipogon) and Endi Tupja',
    folder: 'Aural_Ecologies',
    img_index: 'AE_01.jpg',
    img_main: 'AE_02.jpg',
    img_sub: 'AE_08.jpg',
    slide: {
      width: 0.75,
      img: ['AE_07.jpg', 'AE_03.jpg', 'AE_04.jpg', 'AE_05.jpg', 'AE_06.jpg'],
    },
  },

  // Earthmovers
  {
    title: 'Earthmovers',
    type: ['Film'],
    info: '2020, video, digital, loop 06:34\nTbilisi Architecture Biennial, GE',
    folder: 'Earthmovers',
    img_index: 'EM_01.gif',
    fade_main: [
      'EM_08.jpg',
      'EM_07.jpg',
      'EM_06.jpg',
      'EM_05.jpg',
      'EM_04.jpg',
      'EM_03.jpg',
      'EM_01.jpg',
    ],
    descr: {
      clm: 'one',
      text: 'A short film that reflects on progress, urban development and the dispossession of commons through the lens of extractive violence.\n\n',
    },
    link: [
      {
        hyperlink:
          'Tbilisi Architecture Biennial - Earthmovers - Live Discussion',
        href: 'https://youtu.be/Z3Aw4yQQJaY',
      },
    ],
    img_gal: [{ img: 'EM_12.jpg' }, { img: 'EM_11.jpg' }],
    credit: [
      {
        clm: 'two',
        data: [
          { item: 'Official Selection & Screenings', font: 500, gap: 5 },
          { item: '2021', gap: 5 },
          {
            item: `Kyiv Biennial 'Allied&' 2021, Kyiv, UA`,
            gap: 10,
          },
          {
            item: 'Mediterranea Biennial 19, School of Waters, San Marino',
            gap: 10,
          },
          {
            item: `Wild Out Video Festival, Taipei, TW (Finalist & Most Representative Video)`,
            gap: 10,
          },
          {
            item: 'Video Art and Experimental Film Event, SEA Foundation, Tilburg, NL',
            gap: 10,
          },
          { item: '17th Athens Digital Arts Festival, Athens, GR', gap: 10 },
          {
            item: '12th Edition Balkans Beyond Borders, Pristina, XK (Finalist Competition Programme)',
            gap: 40,
          },
          { item: '2020', gap: 5 },
          {
            item: 'Tbilisi Architecture Biennial, Tbilisi, GE (Premiere)',
            gap: 10,
          },
          {
            item: 'Dnipro Center for Contemporary Culture, Dnipro, UA',
            gap: 10,
          },
          { item: 'Pavilion 13 in Soviet Expo-Center, Kyiv, UA', gap: 10 },
          { item: 'Detector Gallery, Ivano Frankivsk, UA' },
        ],
      },
      {
        clm: 'two',
        data: [
          { item: 'Production and Editing', font: 500 },
          { item: ' Klodiana Millona & Yuan Chun Liu', gap: 10 },
          { item: 'Script', font: 500 },
          { item: 'Endi Tupja & Klodiana Millona', gap: 10 },
          { item: 'Voice', font: 500 },
          { item: 'Endi Tupja', gap: 10 },
          { item: 'Camera', font: 500 },
          { item: 'Endi Tupja', gap: 10 },
          { item: 'CG', font: 500 },
          { item: 'Yuan Chun Liu', gap: 10 },
          { item: 'Sound Composition', font: 500 },
          { item: 'Giulia Francavilla' },
        ],
      },
    ],
  },

  // Leave Us Alone
  {
    title: 'Leave Us Alone',
    type: ['Installations / Interventions', 'Critical Cartography'],
    info: `2019, site specific intervention, found materials from site, 500 x 200 x 200 cm\nin collaboration with workers of Promprylad Factory\n"We Are Never Alone" Metalab Residency, Ivano Frankisck, UA`,
    descr: {
      clm: 'one',
      text: ' Research on factories left behind in Ukraine after social change, and by holding a festival in collaboration with workers, it highlighted the social aspect of factories whereby an isolated community is formed while the times move on and work is absent\n\n',
    },
    link: [
      {
        hyperlink: 'We Are Never Alone Residency - Documentary',
        href: 'https://youtu.be/GETAGxyc3QA',
      },
    ],
    folder: 'Leave_Us_Alone',
    img_index: 'LUA_01.jpg',
    img_main: 'LUA_11.jpg',
    img_gal: [
      { img: 'LUA_03.jpg' },
      { img: 'LUA_04.jpg' },
      { img: 'LUA_15.jpg' },
      { img: 'LUA_06.jpg', orien: 'port' },
      { img: 'LUA_13.jpg' },
      { img: 'LUA_16.jpg' },
    ],
  },

  // You May Dream
  {
    title: 'You May Dream',
    type: ['Pedagogy'],
    info: `2022, workshop / reading session\n"Politics of Space", Cultural Centre SODAS 2123, Vilnius, LT`,
    descr: {
      clm: 'two',
      text: `A reading group session on dreaming and sleeping, its' (im)possibilities and its' implications with space.\n\n`,
    },
    link: [
      {
        hyperlink: 'read more here',
        href: 'https://sodas2123.lt/en/simpoziumas-erdves-politika/',
      },
    ],
    folder: 'You_May_Dream',
    img_index: 'YMD_01.jpg',
    img_main: 'YMD_02.jpg',
    img_sub: 'YMD_03.jpg',
    img_gal: [{ img: 'YMD_04.jpg' }, { img: 'YMD_05.jpg' }],
    credit: [
      {
        clm: 'one',
        data: [
          { item: '*in collaboration with Magdalena Beliavska' },
          { item: '*pictures by Bon Alog' },
        ],
      },
    ],
  },

  // Experiment's Platform
  {
    title: `Experiment's Platform`,
    type: ['Pedagogy'],
    info: '2022, Architektūros Fondas, Vilnius, LT',
    descr: {
      clm: 'two-third',
      text: `Mentor of architectural research projects for the second edition of the Experiments' Platform, an initiative of Architektūros Fondas, focused in alternative pedagogies in architecture and guiding young architects in developing their ideas and practices through experimental research practices / Fixed- Term \n\n`,
    },
    link: [
      {
        hyperlink: `Experiment's Platform\n\n`,
        href: 'https://exp.archfondas.lt/en/',
      },
      {
        hyperlink: 'Architektūros Fondas',
        href: 'https://www.archfondas.lt/en',
      },
    ],
    folder: 'Experiments_Platform',
    img_index: 'EP_01.jpg',
    img_main: 'EP_02.jpg',
    credit: [
      {
        clm: 'two-third',
        data: [
          {
            item: '*pictures by Atdhe Mulla, Giulia Ficarazzo, Jan Liesegang',
          },
        ],
      },
    ],
  },

  // No Money, No Cry
  {
    title: 'No Money, No Cry',
    type: ['Pedagogy'],
    info: `2019, workshop, Oslo Triennial of Architecture,\n'Enough: The Architecture of De-Growth', Oslo, NO`,
    folder: 'No_Money_No_Cry',
    img_index: 'NMNC_01.jpg',
    img_main: 'NMNC_01.jpg',
    descr: {
      clm: 'one',
      text: 'Workshop on collaborative consumption, service (re)distribution and sharing.',
    },
  },

  // Publishing Acts
  {
    title: 'Publishing Acts III:Fiume Novare',
    type: ['Publication'],
    info: '2019, field research & publication, Rijeka, HR',
    descr: {
      clm: 'two',
      text: `Starting off from the official narrative of Rijeka as an open multi-ethnic port, a historic porto franco and a contemporary post-industrial city, which is today boastfully embracing its title of the European Capital of Culture 2020, this publication investigates the ambivalent states of the city's current in-between identity.\n\nIn a collective attempt to unearth the infraordinary and the extraordinary strata of Rijeka, this publication results in forms and formats of counter-cartography, docu-fictional interviews and critical texts on creative economy and culture industry.\n\n`,
    },
    link: [
      {
        hyperlink: 'read more here',
        href: 'https://publishingacts.eu/23/"',
      },
    ],
    folder: 'Publishing_Acts',
    img_index: 'FN_01.jpg',
    img_main: 'FN_03.jpg',
    img_sub: 'FN_02.jpg',
    img_gal: [{ img: 'FN_05.jpg' }, { img: 'FN_04.jpg' }],
  },

  // Taking The Kitchens Side
  {
    title: `Taking The Kitchen's Side`,
    title_second: ' / Do Lado da Cozinha',
    type: ['Pedagogy'],
    info: `2019, Workshop, Lisbon Triennial of Architecture,\n'The Poetics of Reason' , Garagem Sul - CCB, Lisbon, PT`,
    descr: {
      clm: 'one',
      text: `Workshop on politics of kitchen in the framework of the exhibition Agriculture and Architecture: Taking the Country's Side.`,
    },
    folder: 'Taking_The_Kitchens_Side',
    img_index: 'TTKS_01.jpg',
    img_main: 'NMNC_02.jpg',
    img_gal: [
      { img: 'NMNC_07.jpg', orien: 'port' },
      { img: 'NMNC_05.jpg' },
      { img: 'NMNC_04.jpg' },
      { img: 'NMNC_06.jpg' },
    ],
    credit: [{ clm: 'one', data: [{ item: '*pictures by Hugo David' }] }],
  },

  // Capsule
  {
    title: 'Capsule 02',
    type: ['Installations / Interventions'],
    info: '2016, installation, wood, plastic, cart, 160x120x60cm\nDen Haag, NL',
    folder: 'Capsule',
    img_index: 'C_01.jpg',
    img_main: 'C_02.jpg',
    img_gal: [{ img: 'C_01.jpg' }],
  },

  // About Commons
  {
    title: 'What Do We Talk About When We Talk About Commons?',
    title_sub: 'Exhausting Our Own Home',
    type: ['Publication'],
    info: '2019, research, publication, screening\nRotterdam, NL',
    descr: {
      clm: 'one',
      text: 'Long term self initiated investigation on shared living spaces, everyday life in it and its architecture.',
    },
    folder: 'About_Commons',
    img_index: 'AC_01.jpg',
    img_main: 'AC_01.jpg',
    img_gal: [{ img: 'AC_02.jpg' }, { img: 'AC_03.jpg' }],
    fade: {
      clm: 'two-third',
      ch1: [
        'AC_04.jpg',
        'AC_05.jpg',
        'AC_06.jpg',
        'AC_07.jpg',
        'AC_08.jpg',
        'AC_09.jpg',
        'AC_10.jpg',
        'AC_11.jpg',
        'AC_12.jpg',
      ],
    },
  },

  // Ecological Afterlives of A Landfill
  {
    title: 'Ecological Afterlives of A Landfill',
    title_sub: 'The Curtain / The Table',
    type: ['Installations / Interventions'],
    info: '2017, site specific intervention\nrecycled agriculture plastics, 9x12m / the curtain\nscrap wood, gas junction pipe, 0x0,8x0,8m  the table\n Almere, NL',
    descr: {
      clm: 'one',
      text: 'Researching possible afterlives of a landfill through interventions with waste materials.',
    },
    folder: 'Embracing_Braambergen',
    img_index: 'EB_01.jpg',
    img_main: 'EB_02.jpg',
    img_gal: [
      { img: 'EB_04.jpg' },
      { img: 'EB_06.jpg' },
      { img: 'EB_03.jpg' },
      { img: 'EB_05.jpg', orien: 'port' },
    ],
  },

  // It's Not the Fault of the House
  {
    title: `It's Not the Fault of the House`,
    type: ['Installations / Interventions'],
    info: `2018, blue foam model 100x100cm, silkscreen canvas 200x250cm\nPart of 'My Practice, My Politics'Group Exhibition\nSalone Del Mobile, Milano, IT / Stroom, The Hague, NL`,
    descr: {
      clm: 'one',
      text: 'A critical cartography of de-centered social welfare domesticities focusing on unfinished houses in Albania.',
    },
    folder: 'Fault_of_The_House',
    img_index: 'FOH_01.jpg',
    img_main: 'FOH_02.jpg',
    img_gal: [{ img: 'FOH_03.jpg' }, { img: 'FOH_04.jpg' }],
  },

  // Architecture of Citizenship
  {
    title: 'Architecture of Citizenship',
    title_sub: 'Spatial Materiality of Disciplining Technologies of Bodies',
    type: ['Critical Cartography'],
    info: '2021, mixed media, auto-ethnographic research, critical cartography,  drawings, sound\nRotterdam, NL',
    descr: {
      clm: 'one',
      text: `Sketching on an invisible architecture produced from the weaponization of waiting of institutionalised visa regimes, the ear oscillates from waiting
      halls to the raised ridges of the palmar surface of the hands. Between a
      fingertip, a bedroom, and the pulse of the wrist, an ecology of waithood
      is mapped, reading its inscription both extra- and inter-corporeally, with
      the biological body turned into a machine -readable 'text',
      irreversibly stored in repositories of biometric data banks.`,
    },
    folder: 'Arch_of_Citizenship',
    img_index: 'AOC_01.jpg',
    img_main: 'AOC_02.jpg',
    fade: {
      clm: 'two-third',
      ch1: ['AOC_03.jpg', 'AOC_04.jpg', 'AOC_05.jpg'],
    },
  },

  // It's Not the Fault of the House (Publication)
  {
    title: `It's Not the Fault of the House Publication`,
    type: ['Publication'],
    info: '2019, Kajet Journal 3, On Struggle',
    descr: {
      clm: 'one',
      text: 'Text reflecting on life (and its struggle) on tripled peripheries; the periphery of (the) capital, periphery of Europe and periphery of architecture.',
    },
    folder: 'Fault_of_The_House_Publication',
    img_index: 'FOHP_01.jpg',
    fade_main: ['FOHP_01.jpg', 'FOHP_02.jpg', 'FOHP_03.jpg', 'FOHP_04.jpg'],
  },

  // Situated Knowledge
  {
    title: 'Situated Knowledge',
    type: ['Pedagogy'],
    info: '   2018, curating a series of lectures at the Royal Academy of Arts, The Hague, NL',
    descr: {
      clm: 'one',
      text: 'Invited Guests: Su Tomesen, Rikkert Paauw, Charlotte Catala Martin (Woodstone Kugelblitz), Pepijn Kennis (Toestand), Asli Kiyak Ingin, Ana Dzokic & Marc Neelen (STEALTH.unlimited) and Andrea Sollazo (Collective Disaster).',
    },
    folder: 'Situated_Knowledge',
    img_index: 'SK_01.gif',
    img_main: 'SK_02.jpg',
  },

  // There Are Tides In The Body*
  {
    title: 'There Are Tides In The Body*',
    title_sub:
      'Dripping, Sweating, Evaporating, Leaking, Urinating, Menstruating,Crying',
    type: ['Publication'],
    info: '2021, sea, salt, algae, sand, stone, shell and other liquid matters vacuum sealed.\nIonian Sea, AL / North Sea, NL',
    descr: {
      clm: 'one',
      text: 'A publication for a friend and their hydro memories. Borrowing from Virginia Woolf and Astrida Neimanis (Hydrofeminism: Or, on becoming a body of water)',
    },
    folder: 'Tides_In_The_Body',
    img_index: 'SOD_01.jpg',
    img_main: 'SOD_02.jpg',
  },

  // Tutor Urban Studio
  {
    title: 'Tutor Urban Studio',
    type: ['Pedagogy'],
    info: '2018-present, Master of Interior Architecture (INSIDE),\nRoyal Academy of the Arts, The Hague, NL',
    descr: {
      clm: 'one',
      text: 'Guiding first years students in Urban Studio Research and Design\n\nStudio With Care, Albania (2019/2020)\nco-teaching with Benjamin Foerster-Baldenius (raumlaborberlin)\nStudio Results ',
    },
    link: [
      {
        hyperlink: 'here',
        href: 'https://withcarefordervican.cargo.site/practicing-care',
      },
    ],
    folder: 'Tutor_Urban_Studio',
    img_index: 'KABK_01.jpg',
    img_main: 'KABK_02.jpg',
  },
];

export default ProjData;
