import Fade from './Fade';

const Fadewrap = ({ data }) => {
  return (
    <div
      className={`${
        data.fade.clm === 'one'
          ? 'proj-flex__one-clm'
          : data.fade.clm === 'two-third'
          ? 'proj-flex__two-third'
          : 'proj-flex__two-clm'
      } fade__wrap`}>
      {!data.fade.hasOwnProperty('ch2') ? (
        <Fade item={data.fade.ch1} folder={data.folder} />
      ) : (
        <>
          <div className="fade__frame">
            <Fade item={data.fade.ch1} folder={data.folder} />
          </div>
          <div className="fade__frame">
            <Fade item={data.fade.ch2} folder={data.folder} />
          </div>
        </>
      )}
    </div>
  );
};
export default Fadewrap;
