import { useState, useEffect } from 'react';

const useLocalStorage = (key, initValue) => {
  const [value, setValue] = useState(() => {
    let curValue;

    try {
      curValue = JSON.parse(localStorage.getItem(key) || String(initValue));
    } catch (err) {
      curValue = initValue;
    }

    return curValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;
