import ProjList from './ProjList';

const ProjTypeList = ({
  setBoolean,
  setTrvsBoolean,
  typeList,
  setClickedItem,
  toggleLogic,
  matchedItem,
  clickedItem,
  prevItem,
}) => {
  return (
    <ul className="nav__cat-list">
      {typeList.map((item, index) => (
        <li key={index}>
          <div
            className="nav__cat-list__btn"
            onClick={() => {
              setClickedItem(item);
              toggleLogic();
              setBoolean(false);
            }}>
            {item}
          </div>
          <ProjList
            setTrvsBoolean={setTrvsBoolean}
            clickedItem={clickedItem}
            prevItem={prevItem}
            matchedItem={matchedItem}
            item={item}
          />
        </li>
      ))}
    </ul>
  );
};
export default ProjTypeList;
