// import { useRef, useState } from 'react';
// import { useEffect } from 'react';
import useSlide from '../../hooks/useSlide';

const Slide = ({ data }) => {
  const { slideRef, slideContRef, slideContStyle, imgStyle } = useSlide(
    data.slide,
    data.slide.img
  );

  return (
    <div className="proj-flex__one-clm slide" ref={slideRef}>
      <div ref={slideContRef} className="slide__cont" style={slideContStyle}>
        {data.slide.img.map((item, index) => (
          <img
            key={index}
            style={imgStyle}
            src={require(`../../assets/project-img/${data.folder}/${item}`)}
            alt=""
          />
        ))}
      </div>
    </div>
  );
};
export default Slide;
