const ImgMain = ({ data }) => {
  return (
    <img
      src={require(`../../assets/project-img/${data.folder}/${data.img_main}`)}
      alt=""
    />
  );
};

export default ImgMain;
