import { Link } from 'react-router-dom';
import logo from '../../assets/millonaliu_logo.svg';

const HomeBtn = ({ setTrvsBoolean }) => {
  return (
    <Link
      to="/"
      onClick={() => {
        setTrvsBoolean(false);
      }}>
      <img src={logo} alt="" className="home-btn" />
    </Link>
  );
};

export default HomeBtn;
