import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ContextProvider } from "./context/Context";
import ScrollToTop from "./router/ScrollToTop";
import Nav from "./components/nav/Nav";
import IndexPage from "./page/IndexPage";
import ProjectPage from "./page/ProjectPage";
import CV from "./components/cv/CV";
import Header from "./components/layout/Header";
import useTravers from "./hooks/useTravers";

function App() {
  const { clickToggle, setTrvsBoolean, sizeRef, leftComp, rightComp } =
    useTravers("lg");

  return (
    <ContextProvider>
      <BrowserRouter>
        <div className="flex">
          <aside className="flex__nav" ref={sizeRef} style={rightComp}>
            <Nav setTrvsBoolean={setTrvsBoolean} />
          </aside>
          <main className="flex__main" style={leftComp}>
            <ScrollToTop target={".flex__main"} />
            <Routes>
              <Route
                path="/"
                element={<IndexPage setTrvsBoolean={setTrvsBoolean} />}
              />
              <Route path="/cv" element={<CV />} />
              <Route path="/:proj" element={<ProjectPage />} />
            </Routes>
          </main>
          <Header clickToggle={clickToggle} setTrvsBoolean={setTrvsBoolean} />
        </div>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
