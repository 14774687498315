import CVItem from './CVItem';

const CVList = ({ cat }) => {
  return (
    <div className="cv__cat">
      {cat.map((item, index) => (
        <CVItem key={index} item={item} />
      ))}
    </div>
  );
};
export default CVList;
